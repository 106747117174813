<template>
    <div class="page">
        <div class="home-header">
            <app-header :navIndex="0"
                        :changeColor="changeColor"
                        :color="$vuetify.breakpoint.mdAndUp?defaultColor:'rgba(255,255,255,1)'"></app-header>
        </div>
        <!--轮播 v-carousel  v-carousel-item  height="100vh" width="100vw" cycle hide-delimiter-background :show-arrows="false"-->
        <div ref="swiperBanner" class="swiper-box">
            <swiper class="home-swiper" :options="homeSwiperOpts" ref="homeSwiper">
                <swiper-slide v-for="(item, i) in bannerList" :key="i">
                    <div class="banner-box">
                        <video v-if="item.fileType=='video'" x-webkit-airplay="allow" playsinline class="video-box"
                               ref="homeVideo"
                               muted
                               controlslist="nodownload">
                            <source :src="item.url"
                                    type="video/mp4">
                        </video>
                        <v-img v-if="item.fileType=='img'" height="100%" class="banner" :src="item.url"></v-img>
                        <div class="title-box">
                            <div class="ani" v-if="item.enTitle" swiper-animate-effect="fadeIn"
                                 swiper-animate-duration="1s"
                                 swiper-animate-delay="0.2s">{{item.enTitle}}
                            </div>
                            <div class="font-weight-bold ani" v-if="item.title" swiper-animate-effect="fadeIn"
                                 swiper-animate-duration="1s"
                                 swiper-animate-delay="1s">{{item.title}}
                            </div>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="swiper-prev">
                <img src="../../assets/index/left.svg" alt="">
            </div>
            <div class="swiper-next">
                <img src="../../assets/index/right.svg" alt="">
            </div>
        </div>
        <!--数字-->
        <section class="section section-num mt-md-12 mt-sm-6 mt-4">
            <div class="d-flex justify-space-around num-box">
                <div v-for="(item,index) in numberList" :key="index"
                     class="num-item d-flex flex-column justify-center align-center"
                     @click="enter(item.to)">
                    <div class="num">
                        <animated-number :value="item.num" :duration="500" :delay="500*index"
                                         :formatValue="formatToNumber"></animated-number>
                        <span class="unit">{{item.unit}}</span>
                    </div>
                    <div class="text mt-n2 mt-md-n3">{{item.text}}</div>
                </div>
            </div>
        </section>

        <!--关于我们-->
        <section class="mt-md-n8 section-about mt-4">
            <div class="section-container d-flex flex-column flex-md-row">
                <div class="about-img col-md-7 col-sm-12 mb-5 mb-md-0"><!--col-md-7 col-sm-12 pa-0-->
                    <v-img :src="about.img" contain></v-img>
                </div>
                <div class="col-sm-12 d-flex justify-center flex-column text-box ml-md-8 pr-3 align-start">
                    <section-title :title="about.title" :en-title="about.enTitle"></section-title>
                    <div class="desc mt-2">{{about.desc}}</div>
                    <base-btn class="more-btn mt-md-10 mt-2" @click="enter(about.to)">{{$i18n.t('common.moreBtn')}}
                        <span class="iconfont iconjiantou"></span>
                    </base-btn>
                </div>
            </div>
            <div class="title-edge title-edge-lr">{{about.enTitle}}</div>
        </section>

        <!--我们的产品-->
        <section>
            <div class="title-edge">{{product.enTitle}}</div>
            <div class="bg"></div>
            <div class="section-container mt-12 mt-md-0">
                <div class="d-flex flex-sm-row flex-column">
                    <div class="text-box d-flex justify-center flex-column align-center align-sm-start col-md-6 col-sm-12 pl-md-12">
                        <section-title :title="product.title" :en-title="product.enTitle"></section-title>
                        <div class="desc mt-6 mt-md-12 hidden-sm-and-down">{{product.desc}}</div>
                        <base-btn class="more-btn mt-md-10 mt-2 hidden-sm-and-down" @click="enter(product.to)">
                            {{$i18n.t('common.moreBtn')}}
                            <span class="iconfont iconjiantou"></span>
                        </base-btn>
                    </div>
                    <div class="other-bg col-sm-12 align-center">
                        <div class="desc mt-4 hidden-md-and-up">{{product.desc}}</div>
                        <div class="col-md-6 pa-0 mt-6 mt-md-0">
                            <swiper class="product-swiper"
                                    :options="$vuetify.breakpoint.mdAndUp?productSwiperOpts:productSwiperSmOpts"
                                    ref="productSwiper">
                                <swiper-slide class="product-item" v-for="(info, i) in  product.items" :key="i">
                                    <div class="product-box d-flex flex-wrap"><!--justify-md-end-->
                                        <div class="product-img ma-1 mb-md-6 ml-md-6"
                                             v-for="(item,index) in info.one"
                                             :key="index" @click="enter('/product/wineries',item.id)">
                                            <v-hover v-slot:default="{hover}">
                                                <v-card :elevation="hover ? 6 : 0"
                                                        :class="{ 'on-hover': hover }" tile>
                                                    <v-img class="scaleImg" contain
                                                           :src="$utils.formatImgUrl(item.imgUrl)"></v-img>
                                                    <v-expand-transition>
                                                        <div v-if="hover"
                                                             class="d-flex transition-fast-in-fast-out v-card--reveal white--text">
                                                            {{item.title}}
                                                        </div>
                                                    </v-expand-transition>
                                                </v-card>
                                            </v-hover>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                    <base-btn class="more-btn mt-md-10 mt-5 mb-5 ml-5 ml-md-0 hidden-md-and-up"
                              @click="enter(product.to)">
                        {{$i18n.t('common.moreBtn')}}
                        <span class="iconfont iconjiantou"></span>
                    </base-btn>
                </div>
            </div>
        </section>
        <!--我们的团队-->
        <section>
            <div class="section-container">
                <div class="d-flex flex-sm-row flex-column">
                    <div class="col-md-7 col-sm-12 d-flex flex-column mt-8 mt-md-0">
                        <div class="d-flex flex-row justify-center" v-for="(item,index) in people.items" :key="index"
                             :class="index==0?'align-end':''">
                            <div class="people-img mb-2" v-for="(itemImg,i) in item.one" :key="i"
                                 :class="`${'people-img'+index+i}${i==1||i==4?' ml-2 mr-2':''}`">
                                <v-hover v-slot:default="{hover}">
                                    <v-card :elevation="hover ? 6 : 0"
                                            :class="{ 'on-hover': hover }" tile>
                                        <v-img class="scaleImg" :src="$utils.formatImgUrl(itemImg.imgUrl)"></v-img>
                                        <v-expand-transition>
                                            <div v-if="hover"
                                                 class="d-flex transition-fast-in-fast-out v-card--reveal white--text">
<!--                                                {{itemImg.title}}-->
                                            </div>
                                        </v-expand-transition>
                                    </v-card>
                                </v-hover>
                            </div>
                        </div>
                    </div>
                    <!--<v-img :src="people.img" contain></v-img>-->
                    <div class="text-box d-flex justify-center flex-column align-start pr-2 flex col-sm-12">
                        <section-title :title="people.title" :en-title="people.enTitle"></section-title>
                        <div class="desc mt-2">{{people.desc}}</div>
                        <base-btn class="more-btn mt-md-10 mt-2" @click="enter(people.to)">
                            {{$i18n.t('common.moreBtn')}}
                            <span class="iconfont iconjiantou"></span>
                        </base-btn>
                    </div>
                </div>
            </div>
            <div class="title-edge title-edge-lr">{{people.enTitle}}</div>
        </section>
        <section>
            <div class="other">
                <v-img :src="other.img"></v-img>
                <div class="inner section-container d-flex flex-row justify-space-between">
                    <div class="flex d-flex flex-column justify-center align-center"
                         v-for="(item,index) in other.items"
                         :class="index==1?'border':''">
                        <span :class="'iconfont white--text '+item.iconfont"></span>
                        <div class="text white--text">
                            {{item.title}}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import AnimatedNumber from "animated-number-vue/src/AnimatedNumber";
    import SectionTitle from "@/pages/index/section-title";
    import AppHeader from "../../components/core/header/index";
    import * as swiperAni from '../../plugins/swiper/js/swiper.animate.min'

    let vm;
    export default {
        components: {
            AppHeader,
            SectionTitle, AnimatedNumber
        },
        data() {
            return {
                homeSwiperOpts: {
                    effect: 'fade',
                    speed: 1500,
//                    loop: true,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
//                    direction: 'vertical',
//                    mousewheel: true,
//                    slidesPerView: 'auto',
//                    spaceBetween: 0,
//                    touchRatio: 0,//触摸变慢
                    on: {
                        init: function () {
                            swiperAni.swiperAnimateCache(this); //隐藏动画元素
                            swiperAni.swiperAnimate(this); //初始化完成开始动画
                            vm.changeSwiperMethod(this.activeIndex)
                        },
                        slideChange: function () {
                            vm.changeSwiperMethod(this.activeIndex)
                        },
                        slideChangeTransitionEnd: function () {
                            swiperAni.swiperAnimate(this);
                            /*if (this.activeIndex === 1) {
                             }
                             if (this.activeIndex === 2) {
                             businessVideoPlayer.play();
                             }*/
                        }
                    },
                    navigation: {
                        nextEl: '.swiper-next',
                        prevEl: '.swiper-prev',
                    },
                },
                productSwiperOpts: {
//                    effect: 'fade',
//                    loop: true,
                    effect: 'cube',
                    speed: 1500,
                    cubeEffect: {
                        slideShadows: true,
                        shadow: true,
//                        shadowOffset: 100,
//                        shadowScale: 0.6,
                        shadowOffset: 0,
                        shadowScale: 0
                    },
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    /* direction: 'vertical',
                     mousewheel: true,*/
                },
                productSwiperSmOpts: {
                    effect: 'fade',
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                },
                bannerList: [/*{
                 title: '尽享信赖 美酒传承',
                 enTitle: 'TRUST IN ONE NAME FOR FINE WINE',
                 //                    url: 'https://sfle.oss-cn-shanghai.aliyuncs.com/kesson-website/video/home.mp4',
                 url: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/video.mp4',
                 fileType: 'video'
                 }, {
                 title: '尽享信赖 美酒传承',
                 enTitle: 'TRUST IN ONE NAME FOR FINE WINE',
                 url: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/swiper_img.jpg',
                 fileType: 'img'
                 }, {
                 url: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/banner/WechatIMG2554.jpeg',
                 fileType: 'img'
                 }, {
                 url: ' https://website-asc.oss-cn-shanghai.aliyuncs.com/banner/WechatIMG2555.jpeg',
                 fileType: 'img'
                 }, {
                 url: ' https://website-asc.oss-cn-shanghai.aliyuncs.com/banner/WechatIMG2556.jpeg',
                 fileType: 'img'
                 },*/ {
                    title: '尽享信赖 美酒传承',
                    enTitle: 'TRUST IN ONE NAME FOR FINE WINE',
                    url: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/swiper_img_5.jpg',
                    fileType: 'img'
                }, {
                    title: '尽享信赖 美酒传承',
                    enTitle: 'TRUST IN ONE NAME FOR FINE WINE',
                    url: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/swiper_img_6.jpg',
                    fileType: 'img'
                }/*, {
                 title: '尽享信赖 美酒传承',
                 enTitle: 'TRUST IN ONE NAME FOR FINE WINE',
                 url: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/swiper_img_3.jpg',
                 fileType: 'img'
                 }*/, {
                    title: '尽享信赖 美酒传承',
                    enTitle: 'TRUST IN ONE NAME FOR FINE WINE',
                    url: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/swiper_img_7.jpg',
                    fileType: 'img'
                }],
                numberList: this.$i18n.t('index.numberList'),
                about: this.$i18n.t('index.about'),
                product: this.$i18n.t('index.product'),
                people: {
                    img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/people_img.png',
                    title: '我们的团队',
                    enTitle: 'OUR PEOPLE',
                    desc: this.$i18n.t('index.people.desc'),
                    to: '/employee/index',
                    items: [{
                        one: [{
                            imgUrl: 'employee/index/img_two.jpg',
//                            img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/people_one.jpg',
                            title: 'PG'
                        },
                            {
//                                img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/index/img_three.jpg',
                                imgUrl: 'index/people_two.jpg',
                                title: 'Sales'
                            }, {
//                                img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/index/img_four.jpg',
                                imgUrl: 'index/people_three.jpg',
                                title: 'Marketing'
                            }]
                    }, {
                        one: [{
//                            img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/index/img_six.jpg',
                            imgUrl: 'index/people_four.jpg',
                            title: 'IT'
                        },
                            {
                                imgUrl: 'employee/index/img_five.jpg',
                                title: 'Finance',
                            }, {
                                imgUrl: 'employee/index/img_one.jpg',
//                                img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/people_five.jpg',
                                title: 'Wine education'

                            }]
                    }]
                },
                other: {
                    img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/bg.jpg',
                    items: this.$i18n.t('index.items')
                },
                changeColor: 'rgba(255,255,255,0.5)',
                defaultColor: 'rgba(255,255,255,0)',
                textColor: 'rgba(255,255,255,1)'
            }
        },
        created() {
            vm = this
            this.initData()
            /* this.product.items = [{
             id: 1,
             img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/logo_1.jpg',
             to: '/product/wineries',
             title: 'LEEUWIN ESTATE'
             }, {
             id: 2,
             img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/logo_2.jpg',
             to: '/product/wineries',
             title: 'HENSCHKE'
             }, {
             id: 3,
             img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/logo_3.jpg',
             to: '/product/wineries',
             title: 'MUGA'
             }, {
             id: 4,
             img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/logo_4.jpg',
             to: '/product/wineries',
             title: 'TOMI NI OKA'
             }, {
             id: 5,
             img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/logo_5.jpg',
             to: '/product/wineries',
             title: 'MASI'
             }, {
             id: 6,
             img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/logo_6.jpg',
             to: '/product/wineries',
             title: 'YALUMBA '
             }, {
             id: 7,
             img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/logo_7.jpg',
             to: '/product/wineries',
             title: 'GAJA'
             }, {
             id: 8,
             img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/index/logo_8.jpg',
             to: '/product/wineries',
             title: 'BANFI'
             }]*/
        },
        mounted() {
            this.$forceUpdate()
            window.addEventListener("scroll", this.handleScroll);
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "首页"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            initData() {
                this.$api.web.getHomeProduct({}).then(res => {
                    var list = []
                    var array = []
                    var size = (res.rows.length / 8) + 1
                    res.rows.forEach((item, index) => {
                        array.push({id: item.id, imgUrl: item.imgUrl, title: item.title})
                        if (array.length == 8 || (res.rows.length < size * 8 && index == res.rows.length - 1)) {
                            list.push({one: array})
                            array = []
                        }
                    })
//                    console.log(list)
                    this.product.items = list
                })
                this.$api.web.getHomeEmployee({}).then(res => {
                    var list = []
                    var array = []
                    res.rows.forEach((item, index) => {
                        array.push({id: item.id, imgUrl: item.imgUrl, title: item.title})
                        if (array.length == 3 || (res.rows.length < 3 && index == res.rows.length - 1) || (res.rows.length < 6 && index == res.rows.length - 1)) {
                            list.push({one: array})
                            array = []
                        }
                    })
//                    console.log(list)
                    this.people.items = list
                })
            },
            handleScroll() {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                let oneHeight = this.$refs.swiperBanner.offsetHeight
                let num = scrollTop / oneHeight > 1 ? 1 : scrollTop / oneHeight
                this.changeColor = 'rgba(255,255,255,' + num + ')'
                this.defaultColor = 'rgba(255,255,255,' + num + ')'

                /* if (scrollTop > oneHeight) {
                 //这是滑动到scrollTwo的距离要做的操作
                 this.changeColor = 'rgba(255,255,255,1)'
                 this.defaultColor = 'rgba(255,255,255,1)'
                 } else {
                 this.changeColor = 'rgba(255,255,255,0.5)'
                 this.defaultColor = 'rgba(255,255,255,0)'
                 }*/
            },
            changeSwiperMethod(e) {
                var index = e
                var item = this.bannerList[index]
//                console.log(e, item)
                if (item && item.fileType == 'img') {
                } else if (item && item.fileType == 'video') {
                    var myVid = this.$refs.homeVideo[index]
//                    console.log('video', myVid.play())
                    myVid.play(); //播放视
                    myVid.onpause = function () {
                        myVid.play()
//                        console.log('停')
                    }
                }
            },
            formatToNumber(value) {
                return `${value.toFixed(0)}`;
            },
            enter(url, id) {
                if (url == '/company/about' || url == '/company/history') {
                    this.$store.state.currentIndex = 1
                } else if (url == '/product/acting' || url == '/product/wineries') {
                    this.$store.state.currentIndex = 2
                }
                if (id) {
                    this.$router.push({path: url, query: {id: id}})
                } else {
                    this.$router.push(url)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page {
        /*background-color: #eff2f5;*/
        position: relative;

        .home-header {
            position: absolute;
            z-index: 2;
        }

        @media (max-width: $screen-md) {
            background-color: #fff;
        }
    }

    .swiper-box {
        position: relative;
        width: 100vw;
        height: 100vh;

        .home-swiper {
            width: 100%;
            height: 100%;
        }

        .swiper-prev {
            @include tb();
            left: 3%;
            cursor: pointer;
            z-index: 1;
            outline: none;
            width: 80px;
            height: 80px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 5px;

            &.swiper-button-disabled {
                display: none;
            }

            img {
                width: 40%;
                @include ct();
            }
        }

        .swiper-next {
            @include tb();
            right: 3%;
            cursor: pointer;
            z-index: 1;
            outline: none;
            width: 80px;
            height: 80px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 5px;

            &.swiper-button-disabled {
                display: none;
            }

            img {
                width: 40%;
                @include ct();
            }
        }
    }

    .banner-box {
        position: relative;
        height: 100%;

        .video-box {
            width: 100%;
            /*height: 100%;*/
            object-fit: cover;
        }

        .title-box {
            @include tb();
            right: 10%;
            text-align: right;
            color: $primary-color;

            div:first-child {
                font-size: 25px;
                margin: 5px;
                @media (max-width: $screen-md) {
                    font-size: 20px;
                }
                @media (max-width: $screen-sm) {
                    font-size: 12px;
                }
            }

            div:last-child {
                letter-spacing: 10px;
                font-size: 45px;
                @media (max-width: $screen-md) {
                    font-size: 30px;
                }
                @media (max-width: $screen-sm) {
                    font-size: 16px;
                }

                &:before {
                    content: "";
                    width: 20%;
                    height: 4px;
                    background: $primary-color;
                    position: absolute;
                    bottom: -20px;
                    right: 5px;
                    @media (max-width: $screen-md) {
                        height: 2px;
                        bottom: -10px;
                    }
                }
            }
        }
    }

    .section-num {
        position: relative;
        height: 143px;
        margin: auto;
        z-index: 1;
        width: 895px;

        .num-box {
            position: absolute;
            width: 100%;
            z-index: 1;
        }

        .num-item {
            width: 143px;
            height: 143px;
            background-color: $primary-color;
            color: #fff;
            border-radius: 50%;
            cursor: pointer;

            .num {
                font-size: 40px;
                /*font-weight: bold;*/
                span {
                    font-family: DINAlternate-Bold, DINAlternate;
                }

                .unit {
                    font-size: 0.5em;
                }
            }

            .text {
                font-size: 16px;
                /*font-size: 22px;*/
            }
        }

    }

    section {
        position: relative;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        .desc {
            max-width: 440px;
        }
    }

    .about-img {
        /*width: 60%;*/
        /* @media (max-width: $screen-md) {
             width: 100%;
         }*/
    }

    .title-edge {
        @include tb();
        width: 120px;
        height: 871px;
        font-size: 100px;
        color: rgba(183, 142, 85, 0.20);
        writing-mode: vertical-rl;
        text-align: center;
        left: 0;
        z-index: 1;
    }

    .title-edge-lr {
        left: inherit;
        right: 0;
        writing-mode: vertical-lr;
    }

    .bg {
        position: absolute;
        top: 82%;
        transform: translateY(-82%);
        left: 0;
        right: 0;
        height: 382px;
        background-color: rgba(204, 198, 188, 0.10);
    }

    .desc, .more-btn {
        position: relative;
    }

    .section-about {

    }

    /*.product-swiper{
        .product-item{
            transition: transform 0.5s 0.3s ease;
        }
    }*/
    .product-box {
        width: 100%;
    }

    .product-img {
        width: 260px;
        height: 156px;
        cursor: pointer;
        overflow: hidden;

        .v-card--reveal {
            width: 100%;
            height: 100%;
            background-color: $primary-color;
            align-items: center;
            bottom: 0;
            justify-content: center;
            opacity: .9;
            position: absolute;
            font-size: 16px;
        }
    }

    .banner {
        width: 100%;
        /*transform: scale(1);*/
        /*transition: all ease 0.3s;*/
    }

    .banner {
        &:hover {
            /*transform: scale(1.1);*/
        }
    }

    .people-img {
        width: 200px;
        height: 200px;
        cursor: pointer;
        overflow: hidden;

        .v-card--reveal {
            width: 100%;
            height: 100%;
            background-color: $primary-color;
            /*background-color: #838383;*/
            align-items: center;
            bottom: 0;
            justify-content: center;
            opacity: .9;
            position: absolute;
            font-size: 16px;
        }
    }

    .people-img01, .people-img12 {
        width: 200px;
        height: 280px;
    }

    .other {
        position: relative;

        .inner {
            @include ct();
            width: 80%;

            .iconfont {
                font-size: 50px !important;
            }

            .text {
                font-size: 20px;
            }

            .border {
                border-left: 2px solid white;
                border-right: 2px solid white;
            }
        }
    }

    @media (max-width: $screen-md) {
        .swiper-box {
            width: 100vw;
            height: calc(9 * 100vw / 16);

            .swiper-prev, .swiper-next {
                width: 40px;
                height: 40px;
            }
        }
        .section-num {
            width: 100%;
            height: 52px;

            .num-box {
                padding: 0 40px;
            }

            .num-item {
                width: 52px;
                height: 52px;

                .num {
                    font-size: 20px;
                }

                .text {
                    font-size: 9px;
                    margin-top: -5px;
                }
            }
        }
        .title-edge {
            display: none;
        }
        .bg {
            display: none;
            /*height: 330px;
            top: 200%;
            transform: translateY(-200%);*/
        }
        .other-bg {
            background-color: rgba(204, 198, 188, 0.10);
        }
        .product-img {
            width: 150px;
            height: 100px;

            .v-card--reveal {
                font-size: 10px;
            }
        }
        .people-img {
            width: 105px;
            height: 105px;
        }
        .people-img01, .people-img12 {
            width: 105px;
            height: 150px;
        }
        .other {
            .inner {
                .iconfont {
                    font-size: 25px !important;
                }

                .text {
                    font-size: 12px;
                }

                .border {
                    border-left: 1px solid white;
                    border-right: 1px solid white;
                }
            }
        }
    }

    @media (max-width: $screen-sm) {
        .section-num {
            .num-item {
                .num {
                    font-size: 20px;
                }

                .text {
                    font-size: 9px;
                }
            }
        }
        .product-img {
            width: 80px;
            height: 50px;
        }
    }

    @media (max-width: 375px) {
        /*.bg {
            height: 340px;
            top: 150%;
            transform: translateY(-150%);
        }*/
        .product-img {
            width: 109px;
            height: 75px;
        }
    }

    @media (max-width: 320px) {
        /*.bg {
            height: 328px;
            top: 180%;
            transform: translateY(-180%);
            background-color: rgba(204, 198, 188, 0.10);
        }*/
        .product-img {
            width: 90px;
            height: 55px;
        }
    }
</style>
