<template>
    <div class="section-title-wrap">
        <div class="section-title mt-n5">{{title}}</div>
        <div class="en-title">{{enTitle}}</div>
    </div>
</template>

<script>
  export default {
    name: "section-title",
    data() {
      return {}
    },
    props: ['title', 'enTitle']
  }
</script>

<style lang="scss" scoped>
    .section-title-wrap{
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        .section-title{
            font-size: 48px;
            /*font-weight:600;*/
            color: $primary-color;
        }
        .en-title{
            font-size: 48px;
            color: $secondary-color;
            /*font-weight:bold;*/
            font-family:DINAlternate-Bold,DINAlternate;
        }
        @media (max-width: $screen-md) {
            .section-title{
                /*font-weight:300;*/
                font-size: 28px;
            }
            .en-title{
                /*font-weight:300;*/
                font-size: 28px;

            }
        }
        @media (max-width: $screen-sm) {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            .section-title{
                font-size: 18px;
                line-height: 1;
            }
            .en-title{
                font-size: 18px;
                line-height: 1;
                color: rgba(159, 159, 159, 0.10);

            }
        }
    }

</style>
